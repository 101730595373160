import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { I18n } from '@aws-amplify/core';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { Auth } from 'aws-amplify';
import { storeTokens, setTokenCookie, setRefreshTokenCookie } from './helpers/tokenHelper';


import { setLang, setAuth, setUser } from '../../redux/actions';

const mapStateToProps = (state) => {
	return {
		lang: state.app.lang,
		auth: state.app.auth,
		user: state.user,
	}
}

const Redirect = (props) => {

	const signOut = props.signOut;

	React.useEffect(() => {
		// let redirect = new URLSearchParams(window.location.search).get("redirect") || null;
		// console.log("redirect", redirect);
		// if (redirect) this.setState({ redirect: redirect });

		const handleRedirect = async () => {
			var redirect_uri;
			var authorization_code;
			var clientState;
			let queryStringParams = new URLSearchParams(window.location.search);
			let qsRedirectUri = queryStringParams.get('redirect_uri');
			let qsAuthorizationCode = queryStringParams.get('authorization_code');
			let qsClientState = queryStringParams.get('state');

			/*
			 * For a local sign in the redirect_uri/authorization_code will be in the query string params
			 */
			if (qsRedirectUri) {
				redirect_uri = qsRedirectUri;
				authorization_code = qsAuthorizationCode;
				clientState = qsClientState;
			} else {
				/*
				 * For a federated sign in the redirect_uri/authorization_code will be in the local storage
				 */
				redirect_uri = localStorage.getItem('client-redirect-uri');
				authorization_code = localStorage.getItem('authorization_code');
				clientState = localStorage.getItem('client-state');
				localStorage.removeItem(`client-redirect-uri`);
				localStorage.removeItem(`authorization_code`);
				localStorage.removeItem(`client-state`);
			}

			/*
			 * get the current user session
			 */
			let authInfo = await Auth.currentSession();

			let idToken = authInfo.idToken.jwtToken;
			let accessToken = authInfo.accessToken.jwtToken;
			let refreshToken = authInfo.refreshToken.token;

			/*
			 * Set the ID and access token cookies for fast SSO
			 */
			if (idToken && accessToken && refreshToken) {
				setTokenCookie("id_token", idToken);
				setTokenCookie("access_token", accessToken);

				/*
				 * Set the refresh token cookie. Refresh token cannot be parsed for an an expiry so use the access token to get an expiry.
				 * Although the refresh token has a different (longer) expiry than the access token, this is for the purpose of fast SSO,
				 * so the refresh token cookie will get set again when the id or access token cookie expires
				 */
				setRefreshTokenCookie(refreshToken, accessToken);
			}
			else {
				console.error("Inconsistent application state: Tokens missing from current session");
				return;
			}

			if (authorization_code && redirect_uri) {
				/*
				 * PKCE Flow
				 */

				//Store tokens in DynamoDB
				const response = await storeTokens(authorization_code, idToken, accessToken, refreshToken)

				if (response.status === 200) {
					window.location.replace(redirect_uri + '/?code=' + authorization_code + ((clientState !== undefined) ? "&state=" + clientState : ""));
				}
				else {
					console.error("Could not store tokens. Server response: " + response.data);
				}
			}
			else if (redirect_uri) {
				/*
				 * Implicit Flow
				 */
				window.location.replace(redirect_uri + '/' + idToken + "?relay=https%3A%2F%2Fstore.xecurify.com%2Fmoas%2Fbroker%2Flogin%2Fshopify%2Fbeo-sante.myshopify.com%2Faccount")
			}
			else {
				/*
				 * Sign in directly to broker (not from redirect from client as part of oauth2 flow)
				 */
				//props.history.push('/dashboard');
			}
		};

		handleRedirect();
	});


	return (
		<div>
			{I18n.get("LANDING_PAGE_WAIT_REDIRECTION")}
			<Box sx={{mt: 2}}>
				<Button
					variant="contained"
					color="primary" onClick={signOut}>
					{I18n.get("HEADER_MENU_ITEM_LOGOUT")}
				</Button>
			</Box>
		</div>
	);
}

export default withRouter(connect(mapStateToProps, { setLang, setAuth, setUser })(Redirect));
